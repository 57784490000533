<template>
    <div class="thanks">
        <h1>Merci pour votre commande</h1>
        <p>Vous allez bientôt recevoir un mail récapitulatif sur votre adresse <a :href="'mailto:'+customer?.mail">{{customer?.mail}}</a>. <br>
        Vous pouvez retrouver le détail de votre transaction <a :href="transaction">ici</a>.</p>
    </div>
</template>
<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
// import { useRouter } from 'vue-router'
export default {
    setup() {
        const store = useStore()
        // const router = useRouter()
        // onMounted(() => {
        //     if(!store.getters.account) router.push('/')
        // })
        console.log(store.getters)
        return {
            customer:computed(() => store.getters.customer),
            transaction:computed(()=> store.getters.transaction)
        }
    }
}
</script>