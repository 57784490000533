<template>
    <div class="connect">
        <div v-if='!account' class="no-account">
          <h2>{{status.id ===0 ? "Connectez votre portefeuille pour continuer" : "Ravi de vous revoir !"}}</h2>
        <div v-if='status.id ===0' class="providers">
            <button @click="connectCoinbase">Coinbase Wallet</button>
  <button @click="connectTorus">Torus</button>
  <button @click="connectMetamask">Metamask</button>
  <button @click="connectWalletConnect">WalletConnect</button>
        </div>
        <div v-if="status.id===1" class="cached">
          <button @click='connectCachedProvider'>Continuer avec {{status.provider}}</button>
          <p><a @click="clearLocalAndNewAuth">Me connecter avec un autre service</a></p>
        </div>
        </div>
        <div v-else class='authenticated'>
          <h2>Connexion au wallet établie</h2>
          <p><code>{{account.address}}</code></p>
          <button @click="$emit('complete')">Finaliser mon achat</button>
        </div>

    </div>
</template>
<script>
import WalletConnectProvider from "@walletconnect/web3-provider";
import Torus from "@toruslabs/torus-embed";
import Web3Modal from "web3modal";
import { WalletLink } from "walletlink";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import { useStore } from "vuex";
import { onMounted, computed, ref } from '@vue/runtime-core';
export default {
  setup() {
    const store = useStore();
    const status = ref({
      id:0,
      provider:null
    }); /** 0 = non authenticated, 1= cached provider detected, 2 = authenticated */
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: process.env.VUE_APP_INFURA_ID // required
        }
      },
      torus: {
        package: Torus, // required
        options: {
          networkParams: {
            host:
              "https://ropsten.infura.io/v3/" + process.env.VUE_APP_INFURA_ID, // optional
            chainId: 3, // optional
            networkId: 3 // optional
          }
        }
      },
      "custom-walletlink": {
        display: {
          logo:
            "https://raw.githubusercontent.com/walletlink/walletlink/master/web/src/images/wallets/coinbase-wallet.svg", // Path to wallet link logo. Source https://github.com/walletlink/walletlink/blob/master/web/src/images/wallets/coinbase-wallet.svg
          name: "Coinbase Wallet",
          description: "Scan with Coinbase Wallet to connect"
        },
        options: {
          appName: "Dom Pérignon x Lady Gaga", // Your app name
          networkUrl: `https://ropsten.infura.io/v3/${process.env.VUE_APP_INFURA_ID}`,
          chainId: 3
        },
        package: WalletLink,
        connector: async (_, options) => {
          const { appName, networkUrl, chainId } = options;
          const walletLink = new WalletLink({
            appName,
            appLogoUrl:"https://raw.githubusercontent.com/walletlink/walletlink/master/web/src/images/wallets/coinbase-wallet.svg", // Path to wallet link logo. Source https://github.com/walletlink/walletlink/blob/master/web/src/images/wallets/coinbase-wallet.svg
            darkMode:true

          });
          const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
          await provider.enable();
          return provider;
        }
      }
    };
     const modal = new Web3Modal({
        network: "ropsten", // optional
        cacheProvider: true, // optional
        disableInjectedProvider: false,
        providerOptions, // required
      });
    const connectCoinbase = async () => {
     
      try {
        const connection = await modal.connectTo("custom-walletlink");
        // const provider = new ethers.providers.Web3Provider(connection);
        store.commit("setAccount", { address:connection._addresses[0], connection });
        status.value = {
          id:2,
          provider:'Coinbase Wallet'
        }
        // A fix is coming soon to allow Coinbase Wallet info to be cached
        // https://github.com/walletlink/walletlink/pull/150
        // localStorage.setItem('last_provider', '"custom-walletlink"')
      } catch (e) {
        throwError(e)
      }
    };
    const connectTorus = async () => {
     
      try {
        const connection = await modal.connectTo("torus");
        // const provider = new ethers.providers.Web3Provider(connection);
        localStorage.setItem('last_provider', '"torus"')
        status.value = {
          id:2,
          provider:'Torus'
        }
        store.commit("setAccount", { address:connection.selectedAddress, connection });
       
      } catch (e) {
        throwError(e)
      }
    };
    const connectMetamask = async () => {
     
      try {
        const connection = await modal.connectTo("injected");
        localStorage.setItem('last_provider', '"injected"')
        status.value = {
          id:2,
          provider:'Metamask'
        }
        store.commit("setAccount", { address:connection.selectedAddress, connection });
      } catch (e) {
        throwError(e)
      }
    };
    const connectWalletConnect = async () => {
     
      try {
        const connection = await modal.connectTo("walletconnect");
                localStorage.setItem('last_provider', '"walletconnect"')
      status.value = {
          id:2,
          provider:'WalletConnect'
        }
        store.commit("setAccount", { address:connection.selectedAddress, connection });
      } catch (e) {
        throwError(e)
      }
    };
    const throwError = (e) => {
      let msg = ''
      console.log(e)
      if(e === 'Modal closed by user') msg = 'Connexion interrompue par l\'utilisateur'
      else msg = "Une erreur est survenue"
        Toastify({
          text: msg,
          duration: 3000,
          gravity: "top", // `top` or `bottom`
          position: "center", // `left`, `center` or `right`
          backgroundColor: "indianred",
          onClick: function() {} // Callback after click
        }).showToast();
    }
    onMounted(() => {
      if(localStorage.getItem('last_provider')) {
        status.value.id = 1;
        console.log(localStorage.getItem('last_provider'))
        switch(localStorage.getItem('last_provider')) {
        case '"custom-walletlink"' :
           status.value.provider = 'Coinbase Wallet'
        break;
        case '"torus"' :
           status.value.provider = "Torus"
        break;
        case '"walletconnect"' :
          status.value.provider = "WalletConnect"
        break;
        case '"injected"' :
          status.value.provider = "Metamask"
        break;
      }
      }
    })
    const clearLocalAndNewAuth = () => {
      localStorage.clear()
      status.value = {id:0, provider:null}
    }
    const connectCachedProvider = () => {
      switch(localStorage.getItem('last_provider')) {
        case '"custom-walletlink"' :
          connectCoinbase()
        break;
        case '"torus"' :
          connectTorus()
        break;
        case '"walletconnect"' :
          connectWalletConnect()
        break;
        case '"injected"' :
          connectMetamask()
        break;
      }
    }
    return {
      connectCoinbase,
      connectTorus,
      connectMetamask,
      connectWalletConnect,
      connectCachedProvider,
      clearLocalAndNewAuth,
      status,
      account:computed(() => store.getters.account)
    };
  }
};
</script>
