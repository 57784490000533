<template>
    <div class="customer">
        <form @submit.prevent="setCustomer">
            <div class="form-group">
                <input required type="text" v-model='customer.lastname' placeholder="Nom">
                <input required type="text" v-model='customer.name' placeholder="Prénom">
            </div>
            <div class="form-group">
                <input required type="email" v-model="customer.mail" placeholder="Mail">
            </div>
            <div class="form-group">
                <input required type="text" v-model='customer.address.street' placeholder='Voie'>
                </div>
            <div class="form-group">
                <input required type="number" v-model='customer.address.zipcode' placeholder="Code postal">
                <input required type="text" v-model='customer.address.city' placeholder="Ville">
            </div>
            <div class="form-group with-label">
                <label>Date de naissance</label>
                <div class="fields">
                    <select v-model='customer.birthDate.day'>
                    <option value="">Jour</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>          
                </select>
                <select v-model='customer.birthDate.month'>
                    <option>Mois</option>
                    <option value="1">Janvier</option>
                    <option value="2">Février</option>
                    <option value="3">Mars</option>
                    <option value="4">Avril</option>
                    <option value="5">Mai</option>
                    <option value="6">Juin</option>
                    <option value="7">Juillet</option>
                    <option value="8">Août</option>
                    <option value="9">Septembre</option>
                    <option value="10">Octobre</option>
                    <option value="11">Novembre</option>
                    <option value="12">Décembre</option>
                </select>
                <select required v-model='customer.birthDate.year'>
                    <option>Année</option>
                    <option value="1940">1940</option>
                    <option value="1941">1941</option>
                    <option value="1942">1942</option>
                    <option value="1943">1943</option>
                    <option value="1944">1944</option>
                    <option value="1945">1945</option>
                    <option value="1946">1946</option>
                    <option value="1947">1947</option>
                    <option value="1948">1948</option>
                    <option value="1949">1949</option>
                    <option value="1950">1950</option>
                    <option value="1951">1951</option>
                    <option value="1952">1952</option>
                    <option value="1953">1953</option>
                    <option value="1954">1954</option>
                    <option value="1955">1955</option>
                    <option value="1956">1956</option>
                    <option value="1957">1957</option>
                    <option value="1958">1958</option>
                    <option value="1959">1959</option>
                    <option value="1960">1960</option>
                    <option value="1961">1961</option>
                    <option value="1962">1962</option>
                    <option value="1963">1963</option>
                    <option value="1964">1964</option>
                    <option value="1965">1965</option>
                    <option value="1966">1966</option>
                    <option value="1967">1967</option>
                    <option value="1968">1968</option>
                    <option value="1969">1969</option>
                    <option value="1970">1970</option>
                    <option value="1971">1971</option>
                    <option value="1972">1972</option>
                    <option value="1973">1973</option>
                    <option value="1974">1974</option>
                    <option value="1975">1975</option>
                    <option value="1976">1976</option>
                    <option value="1977">1977</option>
                    <option value="1978">1978</option>
                    <option value="1979">1979</option>
                    <option value="1980">1980</option>
                    <option value="1981">1981</option>
                    <option value="1982">1982</option>
                    <option value="1983">1983</option>
                    <option value="1984">1984</option>
                    <option value="1985">1985</option>
                    <option value="1986">1986</option>
                    <option value="1987">1987</option>
                    <option value="1988">1988</option>
                    <option value="1989">1989</option>
                    <option value="1990">1990</option>
                    <option value="1991">1991</option>
                    <option value="1992">1992</option>
                    <option value="1993">1993</option>
                    <option value="1994">1994</option>
                    <option value="1995">1995</option>
                    <option value="1996">1996</option>
                    <option value="1997">1997</option>
                    <option value="1998">1998</option>
                    <option value="1999">1999</option>
                    <option value="2000">2000</option>
                    <option value="2001">2001</option>
                </select>
                </div>
            </div>
            <div class="form-group with-label">
                <label>Adresse du portefeuille</label>
                <div class="fields">
                <input type="text" disabled v-model="customer.wallet">

                </div>
            </div>
            <div class="form-group">
                <div class="fields">
                    <input v-model='consent' type="checkbox">
                <label>J'ai lu et j'accepte les Conditions Générales de Vente</label>
                </div>
            </div>
            <button type='submit' :disabled='!consent'>Valider et payer</button>
        </form>
    </div>
</template>
<script>
import { ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
export default {
    setup(props, {emit}) {
        const store = useStore()
        const consent = ref(true)
        const customer = ref({
            birthDate:{
                day:'27',
                month:"6",
                year:"2000"
            },
            address:{
                street:'15 Avenue de Tourville',
                zipcode:'75007',
                city:'Paris'
            },
            wallet:store.getters.account.address,
            lastname:'BLEICHER',
            name:'Alban',
            mail:'albanbleicher@outlook.com'

        })
        const setCustomer = () => {
            store.commit('setCustomer', customer)
            emit('pay')
        }
        return {
            store,
            consent,
            customer,
            setCustomer
        }
    },
}
</script>