<template>
    <div class="cart">
        <button @click='handleClose' class="close">+</button>
        <img class="logo" src="@/assets/images/logo_collab.svg" alt="Dom Pérignon x Lady Gaga">
        <div class="selection-wrapper">
            <h2>Ma séléction</h2>
        <div class="selection-item">
            <img v-if='currentBottle.name.includes("Rosé")' src="@/assets/images/bottle_rose.png" alt="Token illustration">
            <div class="text-content">
                <div @mouseenter='displayInfo=true' @mouseleave="displayInfo=false" class="informations">
                <span class="info-button"> ? </span>
                <p v-if='displayInfo' class ='info-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum, obcaecati sit totam quam similique deleniti tempora aliquam debitis facilis sunt modi quae fugiat! Voluptatibus nobis eos, enim animi repellat reiciendis?</p>
            </div>
                <h3>{{currentBottle.name.replace('Dom Pérignon x Lady Gaga - ', "")}}</h3>
                <span class='price'>{{currentBottle.price}} ETH</span>
            </div>
        </div>
        </div>
        <div class="buy">
            <h4>total</h4>
            <span class="fees">Transaction : {{gasPrice}} ETH</span>
            <span class='price'>{{price}} ETH</span>
            <div v-if='price'>
                <button v-if='price<balance'  @click='$emit("confirm")'>Commander</button>
            <span v-else class='no-funds'> Fonds insuffisants ({{balance}} ETH sur votre wallet)</span>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { computed, onMounted } from '@vue/runtime-core'
import axios from 'axios'
import web3 from 'web3'
import { ethers } from 'ethers'
export default {
    setup(props, {emit}) {
        const displayInfo = ref(false)
        const store = useStore()
        const gasPrice = ref()
        const balance = ref()
        const price = ref()
        price.value = parseFloat(store.getters.bottle.price)
        const handleClose = () => {
            window.controls.exit()
            emit('close')
        }
        onMounted(async() => {
            const {data:{result: {
            ProposeGasPrice
        }}} = await axios.get('https://api-ropsten.etherscan.io/api?module=gastracker&action=gasoracle&apikey=YourApiKeyToken')
        gasPrice.value = web3.utils.fromWei(ProposeGasPrice)
         const provider = new ethers.providers.JsonRpcProvider(`https://ropsten.infura.io/v3/${process.env.VUE_APP_INFURA_ID}`)
        const balanceRes = await provider.getBalance(store.getters.account.address)
        balance.value = ethers.utils.formatEther(balanceRes)
        })
        return {
            displayInfo,
            store,
            gasPrice,
            price,
            balance,
            handleClose,
            currentBottle: computed(() => store.getters.bottle)
        }
    },
}
</script>