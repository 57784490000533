<template>
    <div class="payment">
        <span v-if='txLoading===0'>Transaction en cours</span>
        <span v-if='txLoading===1'>Transaction terminée</span>
        <span v-if='txLoading===2'>Transaction interrompue</span>
    </div>
</template>
<script>
import { onMounted, ref } from '@vue/runtime-core'
import { ethers } from "ethers";
import web3 from 'web3'
import Market from '../contracts/NFTMarket.json'
import { useStore } from 'vuex';
import {useRouter} from 'vue-router'
export default {
    setup() {
        const store = useStore()
        const router = useRouter()
        const txLoading = ref(0)
        onMounted(async () => {
            const provider = new ethers.providers.Web3Provider(
        store.getters.account.connection
      );
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        process.env.VUE_APP_MARKETPLACE_ADRESS,
        Market.abi,
        signer
      );
      const price = web3.utils.toWei(store.getters.bottle.price.toString(), "ether");
      try {
        const transaction = await contract.createMarketSale(
          process.env.VUE_APP_TOKEN_ADRESS,
          store.getters.bottle.tokenId,
          {
            value: price
          }
        );
        console.log(transaction.hash)
         await transaction.wait();
      txLoading.value = 1
      store.commit('setTransaction', transaction.hash )
      setTimeout(() => {
        router.push('/thanks')
      },1500)

      } catch (e) {
      txLoading.value = 2
        console.log(e);
      }
        })
        return {
            store,
            txLoading
        }
    },
}
</script>