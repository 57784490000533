<template>
  <div class="explore">
    <canvas class="bottle"></canvas>
    <transition name='fade' mode='out-in'>
      <Product v-if='bottle.name' @buy='handleBuy' :name='bottle.name' :description='bottle.description' :price='bottle.price' :key='bottle.name' />
    </transition>
    <transition name='fade' mode='out-in'>
      <Connect v-if='currentView === "connect" ' @complete='currentView = "cart" '/>
    </transition>
    <transition name='fade' mode='out-in'>
      <Cart @close='currentView = "explore"' v-if='currentView === "cart"' @confirm="currentView = 'customer' "/>
    </transition>
    <transition name="fade" mode='out-in'>
      <Customer v-if='currentView  === "customer"' @pay="currentView = 'payment'"/>
    </transition>
    <transition name="fade" mode='out-in'>
      <Payment v-if='currentView==="payment"'/>
    </transition>
    <span class="notice">Scrollez pour vous déplacer ou cliquez sur les repères.</span>
  </div>
</template>
<script>
import Product from "@/components/Product.vue";
import Connect from "@/components/Connect.vue";
import Cart from "@/components/Cart.vue";
import Customer from "@/components/Customer.vue";
import Payment from "@/components/Payment.vue";
import { useStore } from 'vuex';
import { computed, ref, watchEffect } from 'vue';
export default {
  components: {
    Product,
    Connect,
    Cart,
    Customer,
    Payment
  },
  setup() {
    const currentView = ref('explore')
    const store = useStore()
    watchEffect(() => {
      store.commit('setCurrentPage', currentView.value)
    })
    const handleBuy = () => {
      currentView.value = "connect"
      document.body.style.cursor="default"
    }
    return {
      store,
      currentView,
      handleBuy,
      bottle:computed(() => store.getters.bottle)
    }
  }
};
</script>
