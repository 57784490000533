<template>
  <div class="product">
    <button @click='handleClose' class="close">+</button>
    <h2>{{name.replace('Dom Pérignon x Lady Gaga -', '')}}</h2>
    <div class="desc">
      <p>
       Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eaque qui facilis cupiditate odio quasi iure ut, dolorem aperiam aliquam optio quisquam ipsam est? Quam eum suscipit maiores corrupti minima nemo!
      </p>
    </div>
    <div class="buy">
      <span class="price">{{price}} ETH</span>
      <button @click="$emit('buy')">ACHETER</button>
    </div>
  </div>
</template>
<script>
import { onMounted, watch } from 'vue'
import gsap from 'gsap'
import {useStore} from 'vuex'
export default {
  props:['name','description', 'price'],
  setup() {
    const store = useStore()
    const handleClose = () => {
      window.controls.exit()
    }
    watch(() => store.getters.currentPage, (newValue) => {
      if(newValue === 'explore') {
        gsap.to('.product h2', {x:-300, opacity:0, duration:1.7})
      gsap.to('.product .desc', {y:100, opacity:0, duration:1.7})
      gsap.to('.product .buy', {y:100, opacity:0, duration:1.7})
      gsap.to('.product .close', {y:-100, opacity:0, duration:1.7})
      }
    })
    onMounted(() => {
      gsap.from('.product h2', {x:-300, opacity:0, duration:1.7})
      gsap.from('.product .desc', {y:100, opacity:0, duration:1.7})
      gsap.from('.product .buy', {y:100, opacity:0, duration:1.7})
      gsap.from('.product .close', {y:-100, opacity:0, duration:1.7})
    })
    return {
      handleClose,
      store
    }
  },
}
</script>
